export const vwTOpx = (value) => {
  let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth;
  // y = w.innerHeight || e.clientHeight || g.clientHeight;

  let result = (x * value) / 100;
  //   document.getElementById("result_vh_px").innerHTML = result;  // affichage du résultat (facultatif)
  return result;
};
export default vwTOpx;
