import React from 'react';

const LoaderLogo = () => {
  return (
    <svg
      className="loderCircle"
      width="263"
      height="263"
      viewBox="0 0 263 263"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69.7863 24.0987C67.5323 19.4997 68.8025 15.24 73.0919 13.1311C76.132 11.6329 79.4272 12.3846 81.5355 15.0782L80.1508 16.2319C78.5839 14.0864 76.1112 13.4756 73.8103 14.6084C70.5359 16.2214 69.6198 19.5884 71.4365 23.2948C73.2585 26.9697 76.6578 28.2121 80.1716 26.4843C82.9722 25.1061 84.1383 22.3081 83.1805 19.2909L84.966 18.9046C86.1269 22.6318 84.5495 26.1554 80.8952 27.9564C76.3454 30.1906 72.03 28.682 69.7863 24.0987Z"
        fill="#F45539"
      />
      <path
        d="M87.3503 7.70232L89.4429 6.98193L100.213 19.8079L98.3706 20.4447L95.8146 17.3596L88.6985 19.8183L88.558 23.8326L86.6943 24.4747L87.3503 7.70232ZM94.7579 15.9867L89.0056 9.05434L88.964 9.07L88.6673 18.0904L94.7579 15.9867Z"
        fill="#F45539"
      />
      <path
        d="M109.833 16.3888L108.235 13.3663C107.678 12.3066 106.626 11.7063 105.585 11.9255L101.728 12.7295L103.066 19.2025L101.27 19.5783L98.0996 4.26757L104.945 2.84246C108.11 2.18472 110.739 3.54197 111.27 6.10507C111.712 8.23489 110.468 10.1925 107.98 11.0694L107.985 11.0903C108.896 11.3305 109.568 11.9621 110.015 12.8182L111.343 15.4178C111.582 15.9138 111.962 16.2165 112.436 16.1173C112.936 16.0129 113.352 15.517 113.571 14.8384L114.7 15.7154C114.253 16.7177 113.461 17.4902 112.4 17.7095C111.197 17.9601 110.354 17.365 109.833 16.3888ZM106.449 10.0672C108.573 9.62346 109.828 8.16181 109.484 6.51224C109.141 4.86267 107.407 4.00134 105.283 4.43983L100.234 5.48909L101.4 11.1164L106.449 10.0672Z"
        fill="#F45539"
      />
      <path
        d="M113.994 8.95518C113.65 4.11087 116.753 0.352356 121.386 0.0182656C124.473 -0.200981 127.351 1.57387 128.471 4.2466L126.81 4.92C125.93 2.84759 123.796 1.49035 121.479 1.65218C117.903 1.90796 115.545 4.87824 115.821 8.81946C116.097 12.6928 118.991 15.3342 122.718 15.0628C126.138 14.8174 128.674 12.1029 128.434 8.73594L119.782 9.35192L119.668 7.71279L130.194 6.96108L130.808 15.5691L129.262 15.6788L128.871 12.6824H128.851C127.664 14.9897 125.462 16.5035 122.682 16.7019C117.986 17.0569 114.337 13.7786 113.994 8.95518Z"
        fill="#F45539"
      />
      <path
        d="M138.496 0.425781L140.703 0.566726L145.705 16.5665L143.764 16.4412L142.592 12.6096L135.086 12.1242L133.41 15.7731L131.442 15.6478L138.496 0.425781ZM142.145 10.934L139.505 2.31548L139.464 2.31026L135.721 10.5164L142.145 10.934Z"
        fill="#F45539"
      />
      <path
        d="M149.719 1.40186L152.129 1.86645L157.491 16.4046L157.533 16.415L160.016 3.3803L161.843 3.73005L158.912 19.093L156.502 18.6284L151.119 4.11112L151.078 4.10068L148.6 17.1093L146.793 16.7648L149.719 1.40186Z"
        fill="#F45539"
      />
      <path
        d="M165.925 4.75781L171.302 6.54311C176.3 8.20312 178.2 11.7737 176.617 16.5554C175.045 21.3162 171.396 23.0493 166.398 21.3893L161.021 19.604L165.925 4.75781ZM166.914 19.8336C170.776 21.1178 173.629 19.7501 174.873 15.9812C176.123 12.1913 174.649 9.3881 170.787 8.10394L167.153 6.89808L163.28 18.6278L166.914 19.8336Z"
        fill="#F45539"
      />
      <path
        d="M178.642 17.3019C180.901 12.7813 185.352 11.2935 189.881 13.5695C194.431 15.8559 195.909 20.3192 193.655 24.8398C191.406 29.3396 186.955 30.8274 182.406 28.5409C177.877 26.2702 176.398 21.8069 178.642 17.3019ZM191.994 24.0098C193.806 20.3818 192.692 16.8164 189.152 15.0416C185.612 13.2615 182.098 14.4987 180.287 18.1319C178.486 21.7443 179.6 25.3044 183.14 27.0793C186.679 28.8541 190.193 27.617 191.994 24.0098Z"
        fill="#F45539"
      />
      <path
        d="M210.657 36.9827C214.191 33.2816 218.616 33.0153 222.068 36.3301C224.514 38.6792 224.941 42.041 223.145 44.9538L221.594 44.0455C223.067 41.8322 222.791 39.2952 220.938 37.5151C218.304 34.9833 214.837 35.2757 211.989 38.2564C209.173 41.2371 209.173 44.8599 212 47.5744C214.254 49.7407 217.273 49.8765 219.767 47.9398L220.74 49.4902C217.643 51.8601 213.806 51.5835 210.865 48.7593C207.2 45.2462 207.132 40.6629 210.657 36.9827Z"
        fill="#F45539"
      />
      <path
        d="M232.031 47.8818L233.426 49.6045L225.128 64.1583L223.9 62.6445L225.904 59.1783L221.156 53.3265L217.351 54.5741L216.107 53.0446L232.031 47.8818ZM226.831 57.7062L231.339 49.9073L231.313 49.8707L222.77 52.6948L226.831 57.7062Z"
        fill="#F45539"
      />
      <path
        d="M231.667 72.0303L233.942 69.4828C234.743 68.5902 234.936 67.3948 234.374 66.4917L232.291 63.1403L226.701 66.6379L225.727 65.077L238.96 56.7979L242.661 62.7436C244.369 65.4894 244.009 68.4336 241.797 69.8222C239.959 70.9706 237.695 70.4799 236.013 68.444L235.993 68.4545C236.081 69.3941 235.722 70.245 235.076 70.9601L233.103 73.1109C232.723 73.5076 232.567 73.967 232.828 74.3846C233.098 74.8178 233.702 75.0371 234.415 75.011L233.988 76.3787C232.9 76.3004 231.901 75.8306 231.328 74.9066C230.667 73.8573 230.928 72.8551 231.667 72.0303ZM236.409 66.6587C237.554 68.5015 239.361 69.1801 240.787 68.2874C242.213 67.3948 242.422 65.4633 241.271 63.6154L238.543 59.2304L233.681 62.2738L236.409 66.6587Z"
        fill="#F45539"
      />
      <path
        d="M240.059 73.3772C244.473 71.3778 249.064 73.0065 250.975 77.2505C252.25 80.0799 251.584 83.3999 249.46 85.3835L248.258 84.0524C249.892 82.5072 250.428 80.0277 249.476 77.903C248.002 74.6248 244.405 73.4294 240.819 75.0528C237.289 76.6502 235.816 80.2834 237.352 83.7027C238.762 86.84 242.177 88.286 245.243 86.8974L241.678 78.968L243.172 78.2946L247.508 87.9414L239.663 91.4911L239.028 90.0765L241.698 88.6722L241.688 88.6514C239.116 88.3277 236.94 86.7825 235.795 84.2351C233.859 79.9285 235.665 75.3661 240.059 73.3772Z"
        fill="#F45539"
      />
      <path
        d="M256.493 93.5319L257.117 95.6617L243.843 105.857L243.296 103.983L246.487 101.566L244.369 94.3254L240.376 93.9965L239.824 92.1016L256.493 93.5319ZM247.898 100.569L255.072 95.1293L255.061 95.0875L246.092 94.3723L247.898 100.569Z"
        fill="#F45539"
      />
      <path
        d="M259.424 104.432L259.814 106.864L248.023 116.887L248.029 116.929L261.09 114.815L261.386 116.652L245.988 119.147L245.598 116.715L257.362 106.676L257.357 106.635L244.317 108.743L244.025 106.927L259.424 104.432Z"
        fill="#F45539"
      />
      <path
        d="M261.818 120.844L261.994 126.524C262.156 131.801 259.459 134.813 254.441 134.97C249.444 135.127 246.565 132.282 246.404 127.004L246.227 121.33L261.818 120.844ZM248.038 126.957C248.163 131.039 250.422 133.253 254.384 133.133C258.366 133.007 260.485 130.658 260.355 126.576L260.235 122.739L247.918 123.12L248.038 126.957Z"
        fill="#F45539"
      />
      <path
        d="M254.441 137.126C259.454 137.69 262.385 141.365 261.823 146.412C261.261 151.481 257.591 154.42 252.578 153.862C247.586 153.303 244.655 149.628 245.222 144.554C245.784 139.506 249.449 136.567 254.441 137.126ZM252.786 152.008C256.81 152.457 259.756 150.176 260.199 146.23C260.636 142.283 258.267 139.407 254.244 138.958C250.24 138.509 247.294 140.79 246.857 144.737C246.419 148.683 248.783 151.565 252.786 152.008Z"
        fill="#F45539"
      />
      <path
        d="M244.8 179.863C249.371 182.155 250.933 186.316 248.798 190.601C247.283 193.64 244.207 195.044 240.901 194.182L241.307 192.428C243.848 193.185 246.185 192.173 247.336 189.871C248.97 186.598 247.664 183.361 243.983 181.518C240.313 179.702 236.862 180.772 235.107 184.285C233.712 187.083 234.472 190.022 237.054 191.833L235.868 193.222C232.692 190.962 231.823 187.198 233.645 183.549C235.909 179.002 240.251 177.582 244.8 179.863Z"
        fill="#F45539"
      />
      <path
        d="M240.724 203.573L239.496 205.421L223.181 201.762L224.264 200.139L228.158 201.036L232.333 194.762L230.021 191.478L231.115 189.834L240.724 203.573ZM229.829 201.485L238.59 203.506L238.616 203.469L233.405 196.114L229.829 201.485Z"
        fill="#F45539"
      />
      <path
        d="M217.606 210.339L220.704 211.769C221.792 212.27 222.989 212.108 223.681 211.304L226.258 208.318L221.271 203.991L222.473 202.597L234.269 212.834L229.694 218.132C227.58 220.581 224.67 221.108 222.692 219.396C221.052 217.971 220.855 215.658 222.296 213.445L222.281 213.429C221.412 213.789 220.495 213.7 219.621 213.293L216.992 212.035C216.503 211.79 216.018 211.774 215.696 212.145C215.363 212.531 215.331 213.178 215.566 213.852L214.139 213.847C213.889 212.782 214.046 211.68 214.754 210.861C215.571 209.921 216.601 209.879 217.606 210.339ZM224.124 213.299C222.703 214.943 222.593 216.869 223.863 217.976C225.134 219.082 227.039 218.707 228.455 217.062L231.828 213.152L227.497 209.394L224.124 213.299Z"
        fill="#F45539"
      />
      <path
        d="M218.787 218.785C221.999 222.424 221.807 227.304 218.324 230.39C216.002 232.446 212.645 232.791 210.13 231.345L211.041 229.8C212.994 230.912 215.518 230.687 217.257 229.147C219.943 226.767 220.021 222.967 217.408 220.007C214.842 217.099 210.943 216.765 208.142 219.245C205.57 221.521 205.206 225.227 207.434 227.753L213.931 221.99L215.013 223.222L207.106 230.233L201.406 223.765L202.567 222.737L204.691 224.882L204.706 224.867C204.253 222.309 205.081 219.767 207.168 217.913C210.713 214.781 215.596 215.162 218.787 218.785Z"
        fill="#F45539"
      />
      <path
        d="M204.493 240.479L202.651 241.711L188.996 232.038L190.615 230.958L193.869 233.291L200.131 229.11L199.256 225.189L200.896 224.093L204.493 240.479ZM195.232 234.346L202.546 239.592L202.583 239.566L200.594 230.765L195.232 234.346Z"
        fill="#F45539"
      />
      <path
        d="M194.972 246.53L192.775 247.631L179.719 239.331L179.678 239.352L185.581 251.228L183.915 252.058L176.955 238.057L179.152 236.956L192.213 245.225L192.254 245.204L186.362 233.349L188.007 232.529L194.972 246.53Z"
        fill="#F45539"
      />
      <path
        d="M180.048 253.718L174.691 255.571C169.715 257.294 166.045 255.602 164.405 250.842C162.776 246.102 164.629 242.505 169.605 240.782L174.962 238.929L180.048 253.718ZM170.142 242.338C166.295 243.669 164.853 246.488 166.144 250.247C167.44 254.021 170.308 255.352 174.16 254.021L177.778 252.768L173.76 241.085L170.142 242.338Z"
        fill="#F45539"
      />
      <path
        d="M162.343 251.479C163.28 256.448 160.635 260.337 155.659 261.282C150.656 262.232 146.778 259.58 145.841 254.611C144.909 249.662 147.548 245.773 152.551 244.823C157.533 243.878 161.411 246.53 162.343 251.479ZM147.668 254.266C148.423 258.254 151.463 260.41 155.352 259.674C159.24 258.938 161.291 255.817 160.536 251.823C159.787 247.856 156.747 245.7 152.853 246.436C148.964 247.177 146.918 250.299 147.668 254.266Z"
        fill="#F45539"
      />
      <path
        d="M118.792 254.851C117.97 259.909 114.482 262.645 109.766 261.877C106.424 261.334 104.17 258.808 104.003 255.389L105.794 255.253C105.835 257.91 107.491 259.847 110.026 260.259C113.628 260.849 116.32 258.636 116.981 254.559C117.616 250.503 115.565 247.517 111.702 246.89C108.62 246.389 106.054 247.992 105.096 251.004L103.42 250.283C104.628 246.567 107.949 244.614 111.968 245.272C116.965 246.076 119.609 249.814 118.792 254.851Z"
        fill="#F45539"
      />
      <path
        d="M95.0083 258.004L92.8844 257.378L91.5205 240.668L93.3893 241.221L93.6913 245.22L100.906 247.35L103.343 244.16L105.232 244.719L95.0083 258.004ZM93.7537 246.948L94.4357 255.937L94.4773 255.947L99.9328 248.77L93.7537 246.948Z"
        fill="#F45539"
      />
      <path
        d="M81.6969 237.87L81.2545 241.257C81.0983 242.448 81.6084 243.544 82.5819 243.972L86.1894 245.559L88.8339 239.504L90.5153 240.245L84.2529 254.569L77.85 251.755C74.8932 250.455 73.5293 247.819 74.5756 245.423C75.445 243.434 77.5897 242.557 80.1248 243.283L80.1352 243.262C79.5314 242.536 79.3492 241.633 79.4793 240.673L79.8958 237.781C79.9895 237.238 79.8541 236.768 79.4064 236.575C78.9379 236.371 78.3133 236.533 77.7406 236.956L77.3242 235.588C78.2664 235.035 79.3596 234.858 80.3487 235.296C81.4887 235.797 81.8375 236.773 81.6969 237.87ZM80.8068 244.99C78.8234 244.118 76.9546 244.578 76.2779 246.123C75.6011 247.668 76.5225 249.38 78.5059 250.252L83.2274 252.324L85.5283 247.062L80.8068 244.99Z"
        fill="#F45539"
      />
      <path
        d="M73.9985 241.502C71.4738 245.653 66.7731 246.905 62.8064 244.483C60.162 242.865 58.8449 239.749 59.48 236.914L61.2239 237.332C60.7398 239.529 61.6976 241.884 63.681 243.095C66.7419 244.963 70.3858 243.919 72.4317 240.547C74.4462 237.232 73.6185 233.401 70.4223 231.448C67.4967 229.658 63.858 230.399 62.1089 233.276L69.5113 237.801L68.6576 239.2L59.6466 233.693L64.1287 226.322L65.4509 227.131L64.035 229.799L64.0558 229.809C66.3619 228.619 69.0272 228.666 71.4061 230.123C75.4353 232.581 76.5129 237.373 73.9985 241.502Z"
        fill="#F45539"
      />
      <path
        d="M49.115 234.195L47.4023 232.791L52.5976 216.859L54.1072 218.091L52.8422 221.891L58.6726 226.657L62.1499 224.663L63.6752 225.911L49.115 234.195ZM52.2332 223.52L49.3909 232.07L49.4273 232.097L57.2254 227.597L52.2332 223.52Z"
        fill="#F45539"
      />
      <path
        d="M40.5472 226.871L38.8501 225.091L42.9001 210.135L42.8689 210.104L33.3009 219.27L32.0151 217.923L43.2958 207.118L44.9928 208.898L40.974 223.854L41.0053 223.885L50.5576 214.734L51.8278 216.065L40.5472 226.871Z"
        fill="#F45539"
      />
      <path
        d="M29.2927 214.703L25.9403 210.125C22.8273 205.87 23.3426 201.856 27.3926 198.875C31.4218 195.91 35.3989 196.615 38.5119 200.869L41.8643 205.447L29.2927 214.703ZM37.1949 201.84C34.7846 198.551 31.6717 198.009 28.4806 200.358C25.2687 202.722 24.8523 205.86 27.2625 209.154L29.5269 212.249L39.4593 204.936L37.1949 201.84Z"
        fill="#F45539"
      />
      <path
        d="M26.1744 197.095C21.7236 199.476 17.2311 198.103 14.8521 193.619C12.4575 189.114 13.8266 184.614 18.2774 182.234C22.7074 179.864 27.1999 181.236 29.5945 185.741C31.9735 190.226 30.6044 194.725 26.1744 197.095ZM19.1468 183.883C15.5757 185.794 14.4305 189.343 16.2941 192.851C18.1577 196.354 21.7392 197.382 25.3103 195.477C28.8657 193.577 30.011 190.027 28.1473 186.519C26.2837 183.011 22.7022 181.983 19.1468 183.883Z"
        fill="#F45539"
      />
      <path
        d="M10.0366 156.372C4.97671 157.072 1.34317 154.53 0.692461 149.779C0.229159 146.418 1.97305 143.51 5.19014 142.351L5.84605 144.032C3.32131 144.852 1.96784 147.007 2.31662 149.555C2.81636 153.183 5.71591 155.109 9.79193 154.545C13.8419 153.961 16.0855 151.121 15.5546 147.232C15.1277 144.131 12.8476 142.142 9.69302 142.106L9.89083 140.289C13.7847 140.357 16.6217 142.962 17.1787 147.007C17.8607 152.045 15.0757 155.678 10.0366 156.372Z"
        fill="#F45539"
      />
      <path
        d="M0.0364395 134.536L0 132.318L15.4972 126.033L15.5284 127.985L11.8168 129.462L11.9365 137.006L15.7002 138.389L15.7315 140.362L0.0364395 134.536ZM10.1874 130.047L1.83239 133.377V133.419L10.2916 136.499L10.1874 130.047Z"
        fill="#F45539"
      />
      <path
        d="M15.2532 115.817L11.8956 116.401C10.7139 116.605 9.82376 117.419 9.70403 118.479L9.26676 122.405L15.8207 123.135L15.6176 124.968L0.115234 123.24L0.890875 116.271C1.25006 113.05 3.34794 110.967 5.94556 111.254C8.10069 111.495 9.56868 113.29 9.63115 115.932H9.65197C10.1621 115.138 10.969 114.694 11.9216 114.533L14.8004 114.073C15.3417 114 15.753 113.734 15.805 113.248C15.8623 112.742 15.5239 112.189 14.9461 111.771L16.1278 110.967C16.9347 111.703 17.4292 112.695 17.3095 113.776C17.1689 115.002 16.3464 115.624 15.2532 115.817ZM8.21522 117.085C8.45468 114.924 7.4604 113.275 5.78939 113.087C4.11837 112.899 2.7597 114.287 2.52024 116.448L1.94762 121.585L7.6426 122.222L8.21522 117.085Z"
        fill="#F45539"
      />
      <path
        d="M9.50562 109.527C4.80493 108.347 2.20731 104.218 3.33694 99.7025C4.08655 96.6904 6.66334 94.498 9.55248 94.263L9.67741 96.0536C7.43898 96.2467 5.48687 97.8597 4.92466 100.12C4.05532 103.607 6.13757 106.781 9.95852 107.742C13.7118 108.681 17.1163 106.75 18.0221 103.111C18.855 99.7755 17.0694 96.5129 13.8055 95.6934L11.7024 104.129L10.1147 103.732L12.6759 93.4644L21.0257 95.5576L20.6509 97.0663L17.6889 96.5077L17.6837 96.5286C19.5005 98.3818 20.2553 100.945 19.5785 103.659C18.4229 108.243 14.1803 110.701 9.50562 109.527Z"
        fill="#F45539"
      />
      <path
        d="M9.06836 83.5196L9.89606 81.4629L26.6114 81.7187L25.8826 83.5301L21.8847 83.4413L19.0684 90.4364L21.9992 93.1769L21.2652 95.0092L9.06836 83.5196ZM20.1616 83.3421L11.1714 83.149L11.1558 83.1908L17.7514 89.3297L20.1616 83.3421Z"
        fill="#F45539"
      />
      <path
        d="M13.5146 73.1318L14.7119 70.9811L30.1571 70.4434L30.1779 70.4068L18.6214 63.9495L19.5272 62.3208L33.1503 69.937L31.953 72.0877L16.5183 72.6567L16.4975 72.6933L28.0332 79.1402L27.1378 80.748L13.5146 73.1318Z"
        fill="#F45539"
      />
      <path
        d="M21.7905 58.75L25.1638 54.1876C28.3028 49.9488 32.2799 49.2598 36.3143 52.2614C40.3278 55.2473 40.8276 59.2668 37.6886 63.5108L34.3153 68.0732L21.7905 58.75ZM36.3767 62.5294C38.8026 59.2512 38.4017 56.1086 35.2211 53.7387C32.0248 51.3583 28.9066 51.8855 26.4808 55.1638L24.2007 58.2489L34.0967 65.6146L36.3767 62.5294Z"
        fill="#F45539"
      />
      <path
        d="M37.6524 50.5702C34.071 47.0048 34.0553 42.3014 37.6212 38.6943C41.2079 35.0715 45.8982 35.0558 49.4797 38.616C53.0455 42.1657 53.0612 46.8691 49.4797 50.4919C45.9138 54.099 41.2183 54.1199 37.6524 50.5702ZM48.1731 39.9419C45.2995 37.0813 41.5775 37.0343 38.7873 39.8532C35.9971 42.6721 36.0751 46.4045 38.9486 49.2651C41.8065 52.1101 45.5286 52.1571 48.3188 49.3382C51.109 46.5193 51.031 42.7869 48.1731 39.9419Z"
        fill="#F45539"
      />
    </svg>
  );
};

export default LoaderLogo;
