// import React from 'react';
import { isMobile } from '../../App';
import vwTOpx from '../../_helper/vwToPxConverter';

const ScrollToSmooth = (target) => {
  if (target) {
    window.scrollTo({
      top:
        typeof target == 'number'
          ? target
          : isMobile()
          ? target.offsetTop
          : target.offsetTop - 50,
      behavior: 'smooth',
    });
  } else {
    return;
  }
};

export default ScrollToSmooth;
