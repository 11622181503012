import gsap, { Linear } from 'gsap';
import vwTOpx from '../../_helper/vwToPxConverter';

const fullScreenMenu = (navExpanded, setNavExpanded) => {
  window.addEventListener('resize', () => {
    document.querySelector('.navImgBg').style.right = -vwTOpx(15) + 'px';
    document.querySelector('.navImgBg').style.top = -vwTOpx(15) + 'px';
  });

  let t1 = gsap.timeline({
    paused: true,
    // onComplete: () => {
    //   props.setNavAnimationInProgress(false);
    // },
  });

  t1.to('.hambOne', {
    duration: 0.8,
    y: 6,
    rotation: 45,
    ease: 'expo.inOut',
  });
  t1.to('.hambTwo', {
    duration: 0.8,
    delay: -0.8,
    y: -6,
    rotation: -45,
    ease: 'expo.inOut',
  });
  t1.fromTo(
    '#navbar',
    {
      justifyContent: 'center',
      height: 72,
      // visibility: 'hidden',
    },
    {
      justifyContent: 'flex-start',
      duration: 1.6,
      delay: -1.6,
      height: '100%',

      ease: 'expo.inOut',
      // visibility: 'visible',
    }
  );
  t1.fromTo(
    '.navImgBg',
    {
      // scale: 1,
      width: '40vw',
      height: '40vw',
      // visibility: 'hidden',
    },
    {
      duration: 1.6,
      delay: -1.6,
      // scale: 10,
      width: '100vw',
      height: '100vh',
      right: 0,
      top: 0,
      borderRadius: 0,
      ease: 'expo.inOut',
      // visibility: 'visible',
    }
  );
  t1.fromTo(
    '.navbarElements',
    {
      autoAlpha: 0,
    },
    {
      duration: 1.6,
      delay: -1.6,
      autoAlpha: 1,
      ease: 'expo.inOut',
      // visibility: 'visible',
    }
  );
  t1.to('body', {
    duration: 0,
    overflow: 'hidden',
    delay: -1.3,
  });

  t1.staggerFrom(
    '.linksNav',
    1,
    {
      y: 100,
      opacity: 0,
      ease: 'expo.out',
    },
    0.1
  );
  t1.reverse();
  const hamburgerButton = document.getElementsByClassName('hamburgerButton')[0];

  hamburgerButton.addEventListener('click', () => {
    t1.reversed(!t1.reversed()).duration(3);
  });

  // const acessNowButton = document.querySelector('.navbarButtonAccess');
  // acessNowButton.addEventListener('click', function () {
  //   t1.reversed(!t1.reversed());
  // });
  hamburgerButton.addEventListener('mouseover', function () {
    gsap.to('.hamb', {
      duration: 0.5,
      paused: navExpanded,
      // rotation: 180,
      // scale: 1.2,
      ease: Linear.easeNone,
      repeat: 0,
    });
  });

  hamburgerButton.addEventListener('mouseout', function () {
    gsap.to('.hamb', {
      duration: 0.5,
      paused: navExpanded,
      // rotation: 0,
      // scale: 1,

      ease: Linear.easeNone,
      repeat: 0,
    });
  });
  const linksNav = document.querySelectorAll('.linksNav');
  linksNav.forEach((item) => {
    item.addEventListener('click', function () {
      t1.reversed(!t1.reversed()).duration(1);
      setNavExpanded((prev) => !prev);
    });
  });
};

export default fullScreenMenu;
