import React, { useEffect, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToSmooth from '../scrollToSmooth/ScrollToSmooth';
// import { BodyScrollBarContext } from '../../layout/Layout';

import navBg from '../../assets/imgs/navbar/navBg.svg';
import navbarElements from '../../assets/imgs/navbar/navbarElements.svg';
import CustomCta from '../customCta/CustomCta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import fullScreenMenu from '../../assets/gsap/fullScreenMenu';
import { isMobile } from '../../App';
import { isPromoActive, isPromoActiveTimer } from '../../layout/Layout';

export const CustomNavbar = (props) => {
  const [navExpanded, setNavExpanded] = useState(false);

  // const bodyScrollBar = useContext(BodyScrollBarContext);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const showAnim = gsap
      .from('#navbar', {
        yPercent: -200,
        paused: true,
        duration: 0.1,
      })
      .progress(1);

    ScrollTrigger.create({
      start: 'top top',
      end: 99999,
      onUpdate: (self) => {
        self.direction === -1 ? showAnim.play() : showAnim.reverse();
      },
    });
    ScrollTrigger.create({
      trigger: '.RootHome',
      start: 'top top-=-80',
      end: 'top+=100 top-=-80',
      // onEnter: () => {
      //   gsap.to('#navbar', {
      //     background: 'rgba(14, 14, 14, 0)',
      //     duration: 0.3,
      //   });
      // },
      // onEnterBack: () => {
      //   gsap.to('#navbar', {
      //     background: 'rgba(14, 14, 14, 0)',
      //     duration: 0.3,
      //   });
      // },
      // onLeave: () => {
      //   gsap.to('#navbar', {
      //     background: 'rgba(14, 14, 14, 1)',
      //     duration: 0.3,
      //   });
      // },
    });
  }, []);

  useEffect(() => {
    if (isMobile()) {
      fullScreenMenu(navExpanded, setNavExpanded);
    }
  }, []);

  // useEffect(() => {
  //   if (navExpanded) {
  //     document.querySelector('.navWrap').classList.remove('navWrapEvents');
  //   } else {
  //     document.querySelector('.navWrap').classList.add('navWrapEvents');
  //   }
  // }, [navExpanded]);

  const collapsetoggle = () => {
    setNavExpanded(!navExpanded);
  };
  return (
    <Navbar
      id="navbar"
      collapseOnSelect
      expand="lg"
      className={
        isPromoActive() || isPromoActiveTimer().isActive
          ? navExpanded
            ? 'nav-color-scroll fixedNavbar navExpanded nav-promo'
            : 'nav-color-scroll fixedNavbar nav-promo'
          : navExpanded
          ? 'nav-color-scroll fixedNavbar navExpanded'
          : 'nav-color-scroll fixedNavbar'
      }
      variant="light"
      fixed="top"
      expanded={navExpanded}
      onToggle={collapsetoggle}
    >
      {isMobile() ? (
        <div className="navImgBg">
          <img src={navbarElements} className="navbarElements" alt="" />
        </div>
      ) : (
        <picture>
          {/* <source type="image/webp" srcSet={test1Webp}  /> */}
          {/* <source media="(max-width: 991px)" srcSet={navBgMobile} /> */}
          <img src={navBg} alt="" className="navImgBg" />
        </picture>
      )}
      {isMobile() ? (
        <Nav className="navBurger" id="navLinksBlack">
          <Nav.Item
            onClick={() => {
              setNavExpanded(!navExpanded);
              // if (!overlayCollapsed) {
              //   bodyScrollBar().track.yAxis.hide();
              // } else {
              //   bodyScrollBar().track.yAxis.show();
              // }
            }}
            href="#"
            className="link hamburgerButton"
            style={{ marginRight: '0' }}
          >
            <div className="toggle-btn">
              <span className="hamb hambOne"></span>
              <span className="hamb hambTwo"></span>
            </div>
          </Nav.Item>
        </Nav>
      ) : (
        ''
      )}
      <div className={navExpanded ? 'navWrap navWrapEvents' : 'navWrap'}>
        <Nav>
          <Nav.Item
            onClick={() => {
              ScrollToSmooth(document.querySelector('#testimonios'));
            }}
            className="linksNav linksNavSmooth"
          >
            Culonas Crew
          </Nav.Item>
          <Nav.Item
            onClick={() => {
              ScrollToSmooth(document.querySelector('#modules'));
            }}
            className="linksNav linksNavSmooth"
          >
            Módulos del programa
          </Nav.Item>
          <Nav.Item
            onClick={() => {
              ScrollToSmooth(document.querySelector('#faq'));
            }}
            className="linksNav linksNavSmooth"
          >
            FAQs
          </Nav.Item>
          <Nav.Link
            href="/nutricion"
            target="_blank"
            rel="noopener noreferrer"
            // onClick={() => {
            //   window.open(
            //     'https://sso.teachable.com/secure/531346/users/sign_in?',
            //     '_blank'
            //   );
            // }}
            className="linksNav linksNavSmooth"
          >
            Curso de Nutrición
          </Nav.Link>
          <Nav.Item
            onClick={() => {
              window.open(
                'https://sso.teachable.com/secure/531346/users/sign_in?',
                '_blank'
              );
            }}
            className="linksNav linksNavSmooth"
          >
            Mis cursos
          </Nav.Item>
          <Nav.Item onClick={() => {}} className="linksNav">
            <CustomCta
              ctaText="INSCRIBIRME"
              onClickEvent={() =>
                ScrollToSmooth(document.querySelector('#payment'))
              }
            />
          </Nav.Item>
          <Nav.Item onClick={() => {}} className="linksNav">
            {/* <FontAwesomeIcon
              icon={faTwitter}
              className="linksNavSmooth socialLinks"
              onClick={() => {
                window.open('https://www.instagram.com/olivia.y.yo/', '_blank');
              }}
            />
            <FontAwesomeIcon
              icon={faFacebookSquare}
              className="linksNavSmooth socialLinks"
              onClick={() => {
                window.open('https://www.instagram.com/olivia.y.yo/', '_blank');
              }}
            /> */}
            <FontAwesomeIcon
              icon={faInstagram}
              className="linksNavSmooth socialLinks"
              onClick={() => {
                window.open('https://www.instagram.com/olivia.y.yo/', '_blank');
              }}
            />
          </Nav.Item>
        </Nav>
      </div>
    </Navbar>
  );
};

export default CustomNavbar;
