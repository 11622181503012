import React from 'react';
import gsap, { Linear } from 'gsap';

const culonasCrew = () => {
  gsap.to('.culonasCrew', {
    rotation: '360',
    ease: Linear.easeNone,
    duration: 10,
    repeat: -1,
  });
};

export default culonasCrew;
