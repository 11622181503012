import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Loader from '../components/loader/Loader';
import LoaderNutri from '../components/loader/nutrition/Loader';
import { isPromoActiveTimer } from '../layout/Layout';
// import Main from '../views/Main';
// import Nutrition from '../views/Nutrition';

// import PoliticaDeCookies from '../views/pages/cookies/PoliticaDeCookies';
// import PoliticaDePrivacidad from '../views/pages/privacity/PoliticaDePrivacidad';
// import TerminosYCondiciones from '../views/pages/terms/TerminosYCondiciones';
// import ExclusionDeResponsabilidad from '../views/pages/exclusion/ExclusionDeResponsabilidad';

// import ThanksPage from '../views/thanksYouPage/ThanksPage';
// import ThanksPageNutri from '../views/thanksYouPageNutri/ThanksPage';

const Main = React.lazy(() => import('../views/Main'));
const ThanksPage = React.lazy(() =>
  import('../views/thanksYouPage/ThanksPage')
);
const Nutrition = React.lazy(() => import('../views/Nutrition'));
const ThanksPageNutri = React.lazy(() =>
  import('../views/thanksYouPageNutri/ThanksPage')
);

const PoliticaDeCookies = React.lazy(() =>
  import('../views/pages/cookies/PoliticaDeCookies')
);
const PoliticaDePrivacidad = React.lazy(() =>
  import('../views/pages/privacity/PoliticaDePrivacidad')
);
const TerminosYCondiciones = React.lazy(() =>
  import('../views/pages/terms/TerminosYCondiciones')
);
const ExclusionDeResponsabilidad = React.lazy(() =>
  import('../views/pages/exclusion/ExclusionDeResponsabilidad')
);

export const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => (
            <Suspense fallback={<div></div>}>
              <div className="nutritionPage">
                <Nutrition {...props} />
              </div>
            </Suspense>
          )}
        />
        <Route
          exact
          path="/curso-olivia-misssy-gluteos-purchased"
          // component={ThanksPage}
          render={(props) => (
            <Suspense fallback={<Loader />}>
              <div className="oliviaPage">
                <ThanksPage {...props} />
              </div>
            </Suspense>
          )}
        />

        <Route
          exact
          path="/oferta-exclusiva-48-h"
          render={(props) =>
            isPromoActiveTimer().isActive ? (
              <Suspense fallback={<Loader />}>
                <div className="oliviaPage">
                  <Main {...props} />
                </div>
              </Suspense>
            ) : (
              <Redirect to="/" />
            )
          }
        />
        <Route
          exact
          path="/nutricion"
          render={(props) => (
            <Suspense fallback={<div></div>}>
              <div className="nutritionPage">
                <Nutrition {...props} />
              </div>
            </Suspense>
          )}
        />
        <Route
          exact
          path="/curso-olivia-misssy-nutricion-purchased"
          // component={ThanksPage}
          render={(props) => (
            <Suspense fallback={<div></div>}>
              <div className="nutritionPage">
                <ThanksPageNutri {...props} />
              </div>
            </Suspense>
          )}
        />

        <Route
          path="/politica-de-cookies"
          exact
          render={(props) => (
            <Suspense fallback={<Loader />}>
              <PoliticaDeCookies {...props} />
            </Suspense>
          )}
        />
        <Route
          path="/politica-de-privacidad"
          exact
          render={(props) => (
            <Suspense fallback={<Loader />}>
              <PoliticaDePrivacidad {...props} />
            </Suspense>
          )}
        />
        <Route
          path="/terminos-y-condiciones"
          exact
          render={(props) => (
            <Suspense fallback={<Loader />}>
              <TerminosYCondiciones {...props} />
            </Suspense>
          )}
        />
        <Route
          path="/exclusion-de-responsabilidad"
          exact
          render={(props) => (
            <Suspense fallback={<Loader />}>
              <ExclusionDeResponsabilidad {...props} />
            </Suspense>
          )}
        />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
