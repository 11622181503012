import React from 'react';
import gsap, { Power1, Linear } from 'gsap';
import Draggable from 'gsap/Draggable';
import ScrollTrigger from 'gsap/ScrollTrigger';

const sliderInfiniteAuto = () => {
  gsap.utils.toArray('.slides-container').forEach((el) => {
    let slideDelay = 0.5;
    let slideDuration = 1;
    let slides = el.querySelectorAll('.slide');
    let dots = document.querySelector('.dots');
    let navDots = [];

    // if (slides.length > 0) {
    let numSlides = slides.length;

    gsap.set(slides, {
      xPercent: function (i, target) {
        return i * 100;
      },
    });

    // let wrap = wrapPartial(-100, (numSlides - 1) * 100);
    var wrap = gsap.utils.wrap(-100, (numSlides - 1) * 100);
    var progressWrap = gsap.utils.wrap(0, 1);
    let timer = gsap.delayedCall(slideDelay, autoPlay).pause();
    let proxy = document.createElement('div');
    gsap.set(proxy, { x: '+=0' });
    let transform = gsap.getProperty(proxy);
    let slideWidth = 0;
    let wrapWidth = 0;
    let animation = new gsap.timeline({ repeat: -1 });
    resize();

    // TODO: REVERT DRAGGABLE & NON INFINITE BUG
    let draggable = new Draggable(proxy, {
      type: 'x',

      trigger: el,
      throwProps: true,
      onPressInit: function () {
        animation.pause();
        timer.pause();
        updateProgress();
      },

      onDrag: updateProgress,

      onThrowUpdate: updateProgress,
      onThrowComplete: function () {
        if (el.classList.contains('sappedSlider')) {
          return;
        } else {
          timer.restart(true);
        }
      },
      onClick: () => {
        if (el.classList.contains('sappedSlider')) {
          return;
        } else {
          timer.restart(true);
        }
      },
      snap: {
        x: el.classList.contains('sappedSlider') ? snapX : 0,
      },
    });

    window.addEventListener('resize', resize);
    let slidesSnapped = el.querySelectorAll('.slideSnapped');
    for (let i = 0; i < slidesSnapped.length; i++) {
      // gsap.set(slides[i], { backgroundColor: colorArray[i] });
      let newDot = document.createElement('div');
      newDot.className = 'dot';
      newDot.index = i;
      navDots.push(newDot);
      // newDot.addEventListener('click', slideAnim);
      dots.appendChild(newDot);
    }

    if (el.classList.contains('sappedSlider')) {
      el.addEventListener('mouseenter', () => {
        animation.pause();
        timer.pause();
        updateProgress();
      });
      el.addEventListener('mouseleave', () => {
        timer.restart(true);
      });
    }

    function autoPlay() {
      animation.play();
      gsap.fromTo(
        animation,
        1,
        { timeScale: 0 },
        { timeScale: 1, ease: Power1.easeIn }
      );
    }
    function updateProgress() {
      // console.log(transform('x'));
      animation.progress(
        progressWrap(-gsap.getProperty(proxy, 'x') / wrapWidth)
      );
      // animation.progress(transform('x') / wrapWidth);
    }

    function resize() {
      let progress = animation.progress();
      slideWidth = slides[0].offsetWidth;
      wrapWidth = slideWidth * numSlides;

      animation
        .progress(0)
        .clear()
        .to(slides, 200, {
          xPercent: '-=' + numSlides * 100,
          ease: Linear.easeNone,
          modifiers: {
            xPercent: wrap,
          },
        })
        .to(
          proxy,
          100,
          { x: -slideWidth * numSlides, ease: Linear.easeNone },
          0
        )
        .progress(progress);
    }

    function snapX(x) {
      return Math.round(x / slideWidth) * slideWidth;
    }

    function wrapPartial(min, max) {
      let r = max - min;
      return function (value) {
        let v = value - min;
        // console.log(((r + (v % r)) % r) + min);
        return ((r + (v % r)) % r) + min;
      };
    }
    // }
  });
};

export default sliderInfiniteAuto;
