import React, { createContext, useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { CustomNavbar as Navbar } from '../components/navbar/Navbar';

// GSAP
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import FsLightbox from 'fslightbox-react';
import Loader from '../components/loader/Loader';
// Libraries
import lazySizes from 'lazysizes'; // DON'T DELETE, IT'S USING

import sliderInfiniteAuto from '../assets/gsap/sliderInfiniteAuto';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import culonasCrew from '../assets/gsap/culonasCrew';
import DeadLinePromo from '../components/countDownPromo/DeadLinePromo';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Draggable, InertiaPlugin);
}
// export const BodyScrollBarContext = createContext(null);
export const PlayerTogglerContext = createContext(null);

export const PromoDates = {
  dateActive: new Date('2021-10-26T00:00:00+02:00'),
  dateExpire: new Date('2021-10-26T23:59:00+02:00'),
  todayDate: new Date(),
};
export const isPromoActiveTimer = () => {
  // localStorage.removeItem('promoTimerCompleted');
  // localStorage.removeItem('promoCurrTime');
  return {
    isActive:
      !localStorage.getItem('promoTimerCompleted') &&
      window.location.pathname === '/oferta-exclusiva-48-h',
    timer: 172800000, // 172800000
  };
};
export const isPromoActive = () => {
  const dateActive = PromoDates.dateActive;
  const dateExpire = PromoDates.dateExpire;
  const todayDate = PromoDates.todayDate;
  return dateActive < todayDate && dateExpire > todayDate;
};

// EXCLUSIVE PROMO HOLIDAY
export const ExclusivePromoDates = {
  dateActive: new Date('2021-10-26T00:00:00+02:00'),
  dateExpire: new Date('2021-10-26T23:59:00+02:00'),
  todayDate: new Date(),
};
export const itsExclusivePromo = () => {
  const dateActive = ExclusivePromoDates.dateActive;
  const dateExpire = ExclusivePromoDates.dateExpire;
  const todayDate = ExclusivePromoDates.todayDate;
  return dateActive < todayDate && dateExpire > todayDate;
};
//

export const Layout = (props) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.addEventListener('load', () => {
      ScrollTrigger.refresh();
    });

    window.addEventListener('scroll', () => {
      ScrollTrigger.update();
      ScrollTrigger.refresh();
    });
  }, []);

  const [toggler, setToggler] = useState({
    opened: false,
    src: null,
  });
  let PlayerToggler = useRef(setToggler);
  const getPlayerToggler = () => {
    return PlayerToggler.current;
  };

  useEffect(() => {
    window.addEventListener('refresh', () => window.scrollTo(0, 0));
    window.onload = () => {
      document.body.classList.add('loaded');
      setLoading(false);
      setTimeout(() => {
        document.querySelector('.loaded #loader-wrapper').style.display =
          'none';
      }, 2000);
    };
  }, []);

  useEffect(() => {
    // GSAP EFFECTS
    if (!loading) {
      sliderInfiniteAuto();
      culonasCrew();
    }
  }, [loading]);

  useEffect(() => {
    var lazyVideos = [].slice.call(document.querySelectorAll('video.lazy'));

    if ('IntersectionObserver' in window) {
      var lazyVideoObserver = new IntersectionObserver(function (
        entries,
        observer
      ) {
        entries.forEach(function (video) {
          if (video.isIntersecting) {
            for (var source in video.target.children) {
              var videoSource = video.target.children[source];
              if (
                typeof videoSource.tagName === 'string' &&
                videoSource.tagName === 'SOURCE'
              ) {
                videoSource.src = videoSource.dataset.src;
              }
            }

            video.target.load();
            video.target.classList.remove('lazy');
            lazyVideoObserver.unobserve(video.target);
          }
        });
      });

      lazyVideos.forEach(function (lazyVideo) {
        lazyVideoObserver.observe(lazyVideo);
      });
    }
  }, []);

  return (
    // <BodyScrollBarContext.Provider value={getBodyScrollBar}>
    <PlayerTogglerContext.Provider
      value={{ getPlayerToggler, toggler, setToggler }}
    >
      {isPromoActive() &&
      window.location.pathname !== '/curso-olivia-misssy-gluteos-purchased' ? (
        <DeadLinePromo />
      ) : isPromoActiveTimer().isActive &&
        window.location.pathname === '/oferta-exclusiva-48-h' ? (
        <DeadLinePromo />
      ) : (
        ''
      )}
      <Container nogutters="true" fluid id="inicio">
        <div className="layoutContainer oliviaPage">
          {window.location.pathname ===
            '/curso-olivia-misssy-gluteos-purchased' ||
          window.location.pathname ===
            '/curso-olivia-misssy-nutricion-purchased' ? (
            ''
          ) : (
            <Navbar />
          )}
          <div className="mainContainer">{props.children}</div>
        </div>
      </Container>

      <FsLightbox
        toggler={toggler.opened}
        sources={[
          <iframe
            src={toggler.src}
            width="1920"
            height="1080"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="VSL - NFT MASTERY"
          ></iframe>,
        ]}
        type="video"
      />
      <Loader />
    </PlayerTogglerContext.Provider>
    // </BodyScrollBarContext.Provider>
  );
};

export default Layout;
