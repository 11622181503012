import React from 'react';
import { Button } from 'react-bootstrap';

const CustomCta = (props) => {
  return (
    <div className={'buttonWrapper'}>
      <Button
        bsPrefix
        onClick={(e) =>
          props.onClickEvent != null
            ? props.onClickEvent()
            : props.hrefTo
            ? window.open(props.hrefTo, '_blank')
            : e.preventDefault
        }
        // href={props.href != null ? props.href : ''}
        className={
          props.customClassBtn != null
            ? props.customClassBtn + ' buttonStyle'
            : 'buttonStyle'
        }
      >
        {props.ctaText2 ? (
          <div className="buttonText">
            {props.ctaText}
            <br />
            <span>{props.ctaText2}</span>
          </div>
        ) : (
          <div className="buttonText">{props.ctaText}</div>
        )}
      </Button>
      {/* <Button
        bsPrefix
        className={
          props.customClassBtn != null
            ? props.customClassBtn + ' buttonStyle'
            : 'buttonStyle'
        }
      >
        <div className="buttonText">{props.ctaText}</div>
      </Button> */}
    </div>
  );
};

export default CustomCta;
