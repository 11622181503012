import React, { useEffect } from 'react';

import Router from './routes/Router';
import axios from 'axios';

// SASS Importing
import './App.scss';

export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua) || window.innerWidth < 991;
};

export const App = (props) => {
  useEffect(() => {
    const onTidioChatApiReady = () => {
      function hideTdo() {
        var timer = null;
        var target = document.querySelector('#tidio-chat iframe');
        if (!target) {
          if (timer !== null) {
            clearTimeout(timer);
          }
          timer = setTimeout(hideTdo, 500);
          return;
        } else {
          var timer2 = null;
          var tdo = document
            .querySelector('#tidio-chat iframe')
            .contentDocument.querySelector('.widgetLabel');
          if (!tdo) {
            if (timer2 !== null) {
              clearTimeout(timer2);
            }
            timer2 = setTimeout(hideTdo, 1);
            return;
          }
          document
            .querySelector('#tidio-chat iframe')
            .contentDocument.querySelector('.widgetLabel').style.display =
            'none';
          return true;
        }
      }

      hideTdo();

      setInterval(hideTdo, 10);
      if (
        window.location.pathname === '/nutricion' ||
        window.location.pathname === '/curso-olivia-misssy-nutricion-purchased'
      ) {
        window.tidioChatApi.setColorPalette('#F45539');
      } else {
        window.tidioChatApi.setColorPalette('#694ed6');
      }
    };

    let tidioChatReadyListener = null;

    if (window.tidioChatApi) {
      window.tidioChatApi.on('ready', onTidioChatApiReady);
    } else {
      tidioChatReadyListener = document.addEventListener(
        'tidioChat-ready',
        onTidioChatApiReady
      );
    }
    return () => {
      if (tidioChatReadyListener) {
        document.removeEventListener(tidioChatReadyListener);
      }
    };
  }, []);
  return (
    <>
      <Router />
    </>
  );
};

export const fbPixel = (ename) => {
  let ua = navigator.userAgent;
  let url = document.URL;
  const text = async (url) => {
    return await fetch(url).then((res) => res.text());
  };
  return text('https://www.cloudflare.com/cdn-cgi/trace').then((data) => {
    let ipRegex = /[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/
    let ip = data.match(ipRegex)[0]

    axios.get('/api/ap.php?e=' + encodeURIComponent(ename))
/*    axios.post('https://graph.facebook.com/v12.0/949527185847449/events?access_token=EAALXJK4pegYBAPrSDH87B69FSEsMTcUWQuJMujBKoYWjyHGvOF99f3vl28bzCRXFl7HWZA4t4xrtKrO5PHbPVTkJ7sun0aZBtVtIycLN7JV6gi0aPikWPTeSmlHge1m4MZBvFumbZCqtyhRadlFYrUbf0IMBX1nG6p9iZCq7C4XsORL1bjR4NQ9EkOZCWtx3AZD',
      { data: [{
        event_name: ename,
        event_time: Math.floor(Date.now() / 1000),
        user_data: {client_ip_address: ip, client_user_agent: ua},
        event_source_url: url,
        action_source: 'website'
      }]}
    )*/
    .then(function (response) {
      return;
    })
    .catch(function (error) {
      return;
    })
});
};

export default App;
