import React from 'react';
import Countdown from 'react-countdown';
import { PromoDates } from '../../layout/Layout';

const CountDownPromo = () => {
  const renderer = ({ formatted: { hours, minutes, seconds }, completed }) => {
    if (completed) {
      // Render a completed state
      // window.location.pathname = '/nutricion';
      return (
        <h2 className="textStroke">
          {hours + 'h '}
          {minutes + 'm '}:{seconds + 's'}
        </h2>
      );
    } else {
      // Render a countdown
      return (
        <h2 className="textStroke">
          {hours + 'h '}: {minutes + 'm '}: {seconds + 's'}
        </h2>
      );
    }
  };

  const dateExpire = PromoDates.dateExpire;
  console.log(dateExpire);
  return (
    <Countdown
      // autoStart={false}
      date={dateExpire.getTime()}
      daysInHours
      // onComplete={(e) => window.location.reload()}
      renderer={renderer}
    />
  );
};

export default CountDownPromo;
