import React from 'react';

const Loader = () => {
  return (
    <div id="loader-wrapper" className="oliviaPage">
      <div id="loader"></div>

      <div className="loader-section section-left"></div>
    </div>
  );
};

export default Loader;
