import React from 'react';
import LoaderLogo from '../../../assets/imgs/nutrition/loader/LoaderLogo';

const LoaderNutri = () => {
  return (
    <div id="loader-wrapper">
      <div id="loader">
        <LoaderLogo />
      </div>

      {/* <div className="loader-section section-left"></div> */}
      <div className="loader-section section-right"></div>
    </div>
  );
};

export default LoaderNutri;
